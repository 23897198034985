.techSection {
  font-family: sans-serif;
}

.techSection h3 {
  font-family: "Archivo", sans-serif;
}

.techRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1vw;
  padding: 1vw;
}

.techRow a {
  height: 5vw;
}

.iconImage {
  height: inherit;
 }

 @media only screen and (min-width:768px) {
  .techSection h3 {
    padding: .5rem;
  }
 }