#aboutLand {
  /* display: flex; */
  
  margin-top: -5vw;
  background-color: #edf9ff;
  background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

#aboutLand p {
  margin-bottom: 0;
  padding:2vw;
  padding-bottom: 1em;
  font-family: "Archivo", sans-serif;
}

#aboutLandInfoDiv {
  display: inline-block;
}

#aboutHeadshot {
  height: 25vh;
  float: left;
  margin: 2vw;

}

@media only screen and (min-width: 765px) {
  #aboutLandInfoDiv {
    display: flex;
    width: 80vw;
    margin: auto;
  }
}