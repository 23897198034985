* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#introDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.bg-video {
  position: absolute;
  margin: 0 auto;
  overflow:hidden;
  width: 100%;
}

#ourSVG {
  
  position: absolute;
  top: 0;
  left:0;
  height: 100vh;
  width: 100%;
}

#name {
  font-size: 120px;
  font-family: "Metal Gear Solid 2";
  position: relative;
  left: 0;
  width: 100%;
  top: 50;
  margin-top: 15%;
  margin-bottom: 20%;
  visibility: hidden;
}

#mask-bg {
  mask: url("#mask")
}

.menuCustom {
  font-family: "Krona", sans-serif;
}

/*Silly overlap thing we have to do for the about section to have its texture*/
#introDiv {
  background-color: #edf9ff;
  background-image: url("https://www.transparenttextures.com/patterns/absurdity.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */}

@media only screen and (max-width: 425px) {
  .bg-video {
    height: auto;
  }

  
}