.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Menu */
ul.ant-menu-dark.ant-menu-root {
  position: sticky;
  top: 0;
  z-index: 100;
}

.ant-menu-sub {
  background-color: #00313d !important;
  background-image: url("https://www.transparenttextures.com/patterns/hexellence.png") !important;
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

/* Projects */
.sectionHead {
  font-family: 'Krona', sans-serif;
}

#aboutLand, #projectLand, #contactLand, #articlesDiv { 
  scroll-margin-top: 280px;

}

#philanthropyIcon {
  height: 76%;
  margin-right: 1vw;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
