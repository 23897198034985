#projectLand {
  background-color: #141414;
  background-image: url("https://www.transparenttextures.com/patterns/cream-pixels.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
  padding-top: 1vw;
  padding-bottom: 1vw;
}

#projectsSectionHead {
  color: lightsalmon;
  margin-bottom: 0;
}

.ant-card {
  margin: 4vw 2vw !important;
  background-color: rgba(255,255,255,.75) !important
}

.ant-card-body {
  display: flex;
  flex-direction: column;
}

.ant-card-meta-title {
  font-family: "Metal Gear Solid 2", sans-serif;
  font-size: 2rem !important;
}

.panelTitle {
  font-family: "Krona", sans-serif;
}

.ant-collapse-header {
  color: black !important;
}

.ant-collapse-content-box p {
  font-family: "Archivo", sans-serif;
}

.projectDescriptionP {
  font-family: sans-serif;
}

.projectLinksUL {
  display: inline-block;
  font-family: "Archivo", sans-serif;
}

.projectLinkDiv {
  padding: 2vw;
}

.projectIcon {
  height: auto;
  max-height: 30vw;
}

@media only screen and (min-width: 425px) {
  /* .ant-card-body {
    flex-direction: row;
  } */

  .ant-collapse {
    width: -webkit-fill-available;
  }

  .projectIcon {
    max-height: 25vw;
  }
}

@media only screen and (min-width:768px) {

  #projectBox {
    width: 80vw;
    margin: auto;
  }


  .ant-card-body {
    flex-direction: row;
  }

  .ant-collapse-content-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .iconImage {
    height: inherit;
    max-height: 3vw;
    margin: auto 1vw;
  }
}
